<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <section class="hero is-primary is-bold">
      <div class="hero-body custom-hero">
        <div class="container is-title-fullhd">
          <div class="level">
            <div class="level-left">
              <div>
                <h1 class="title">Empresa</h1>
              </div>
            </div>

            <div v-if="loaded" class="level-center custom-level-center">
              <div class="box">
                <div
                  v-if="lastValidation.status !== 'PENDENTE'"
                  class="is-flex company-status-container"
                >
                  <b-icon
                    class="rm-2"
                    :icon="company.status === 'HABILITADA' ? 'checkbox-marked' : 'alert-box'"
                    :type="company.status === 'HABILITADA' ? 'is-success' : 'is-warning'"
                    size="is-medium"
                  ></b-icon>
                  <p class="is-size-5 has-text-weight-bold">
                    {{ company.status === 'HABILITADA' ? 'Aprovada' : 'Pendente' }}
                  </p>
                </div>

                <div
                  v-if="lastValidation.status === 'PENDENTE'"
                  class="is-flex company-status-container"
                >
                  <b-icon class="rm-2" icon="information" type="is-info" size="is-medium"></b-icon>
                  <p class="is-size-5 has-text-weight-bold">
                    Em Análise
                  </p>
                </div>

                <div
                  v-if="company.status === 'INABILITADA' && lastValidation.status === 'INVALIDA'"
                  class="is-flex custom-validation-justification"
                >
                  <p>{{ lastValidation.justificativa }}</p>
                </div>
              </div>

              <div v-if="displayRequestValidation" class="has-text-centered">
                <button
                  :disabled="disableRequestValidation"
                  class="button is-primary is-inverted is-outlined"
                  @click.prevent.stop="requestValidation()"
                >
                  Solicitar Validação
                </button>
              </div>
            </div>

            <div class="level-right has-text-centered">
              <div v-if="!loading.general" class="image-container image-color">
                <figure class="image-wrapper">
                  <img :src="imageUrl" />
                </figure>

                <p class="custom-logo-text" v-if="!logo">
                  <strong>Insira a logomarca</strong>
                </p>

                <b-field class="file">
                  <b-upload
                    accept="image/png,image/jpeg"
                    :disabled="disableFields"
                    ref="upload"
                    @input.native="event => sendLogo(event.target.files[0])"
                  >
                    <a :disabled="disableFields" class="button is-small custom-button">
                      <b-icon icon="upload"></b-icon>
                    </a>
                  </b-upload>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fullhd">
        <div class="columns">
          <div class="column is-narrow">
            <MenuSecundario titulo="Perfil" :itens="menu" />
          </div>
          <div class="column">
            <router-view :key="$route.path" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import MenuSecundario from '@/components/MenuSecundario';
import { fileServer } from '@/utils/functions';
import { uploadLimit } from '@/utils/data';

export default {
  name: 'Seller',
  components: {
    MenuSecundario,
  },
  data() {
    const loading = {
      general: false,
    };

    return {
      loading,
      loaded: false,
      logo: null,
      imageUrl: null,
    };
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapState('companiesDocuments', ['companyDocuments']),
    ...mapGetters({
      chooseCompany: 'getChooseCompany',
      lastValidation: 'lastValidation',
      logged: 'getLogged',
      user: 'getUser',
    }),
    disableFields() {
      return (
        !this.logged ||
        !this.user.empresas.some(
          companyUser => companyUser.id === this.company.id && companyUser.editar_empresa,
        )
      );
    },
    displayRequestValidation() {
      return (
        this.company.status === 'INABILITADA' &&
        this.lastValidation.status !== 'PENDENTE' &&
        this.loaded
      );
    },
    disableRequestValidation() {
      if (!this.companyDocuments.length || !this.updatedCertificate) return true;
      if (!this.company.activity) return true;
      if (!this.company.address) return true;
      if (!this.company.city) return true;
      if (!this.company.cnpj) return true;
      if (!this.company.companySize) return true;
      if (!this.company.email) return true;
      if (!this.company.fantasyName) return true;
      if (!this.company.legalNature) return true;
      if (!this.company.IBGECode) return true;
      if (!this.company.municipalRegistration) return true;
      if (!this.company.neighborhood) return true;
      if (!this.company.number) return true;
      if (!this.company.registry) return true;
      if (!this.company.registryDate) return true;
      if (!this.company.registryNumber) return true;
      if (!this.company.registryState) return true;
      if (this.company.registry === 'CARTORIO' && !this.company.registryOffice) {
        return true;
      }
      if (!this.company.shareCapital) return true;
      if (!this.company.shareCapitalDate) return true;
      if (!this.company.telephone) return true;
      if (!this.company.socialDenomination) return true;
      if (!this.company.state) return true;
      if (!this.company.zipCode) return true;
      if (this.disableFields) return true;

      return false;
    },
    firstValidation() {
      return this.company.status === 'CADASTRADA' || this.company.status === 'PRIMEIRA_VALIDACAO';
    },
    menu() {
      return [
        {
          titulo: 'Dados Cadastrais',
          rota: 'CompanyData',
          parametros: { id: this.$route.params.id },
        },
        {
          titulo: 'Junta Comercial/Cartório',
          rota: 'SellerRegistry',
          parametros: { id: this.$route.params.id },
        },
        {
          titulo: 'Enquadramento',
          rota: 'SellerCompanySize',
          parametros: { id: this.$route.params.id },
        },
        {
          titulo: 'Documentação',
          rota: 'SellerDocumentation',
          parametros: { id: this.$route.params.id },
        },
        {
          titulo: 'Usuários',
          rota: 'CompanyUsers',
          parametros: { id: this.$route.params.id },
        },
      ];
    },
    updatedCertificate() {
      const oneDay = 24 * 60 * 60 * 1000;
      const now = new Date();
      const invalidDate = new Date(now.getTime() - oneDay);

      return this.companyDocuments.some(
        document =>
          document.type === 'Certidão Simplificada da Junta Comercial' &&
          new Date(document.createdAt) >= invalidDate,
      );
    },
  },
  methods: {
    ...mapActions('companies', ['fetchCompany', 'sendCompanyLogo']),
    ...mapActions('companiesDocuments', ['fetchCompanyDocuments']),
    ...mapActions(['buscarEmpresaValidacoes', 'fetchLastValidation', 'solicitarValidacaoEmpresa']),
    async loadCompany() {
      this.loading.general = true;
      const companyId = this.chooseCompany ? this.company.id : this.$route.params.id;
      try {
        await Promise.all([
          this.fetchCompany(companyId),
          this.fetchCompanyDocuments(companyId),
          this.fetchLastValidation(companyId),
        ]);

        if (this.company.logo) {
          const url = await fileServer(this.company.logo, 'logomarca');
          this.logo = url;
          this.imageUrl = url;
        }
      } catch (error) {
        this.$alerta('Erro ao buscar dados', 'is-danger');
      } finally {
        this.loading.general = false;
      }
    },
    async requestValidation() {
      const confirmation = await this.$confirmacao({
        message:
          'Deseja prosseguir? Você não poderá fazer mais alterações de enquadramento e deverá aguardar avaliação da SelCorp.',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        type: 'is-warning',
        hasIcon: true,
      });
      if (!confirmation) {
        return;
      }

      this.loading.general = true;
      try {
        await this.solicitarValidacaoEmpresa(this.company.id);
        await this.fetchLastValidation(this.company.id);
        this.$alerta('Solicitação enviada', 'is-success');
      } catch (error) {
        this.$alerta('Erro ao solicitar validação da empresa', 'is-danger');
      }
      this.loading.general = false;
    },
    async sendLogo(file) {
      const imageType = /image.(jpeg|png)$/i;
      if (!file.type.match(imageType)) {
        this.$alerta('Selecione arquivos com extensão .jpg ou .png', 'is-danger');
        this.$refs.upload.$refs.input.value = null;
        return;
      }
      if (file.size > uploadLimit) {
        this.$alerta('Insira um arquivo com até 50MB', 'is-danger');
        return;
      }

      this.loading.general = true;
      try {
        const dataLogo = new FormData();
        dataLogo.append('logo', file);
        await this.sendCompanyLogo({ companyId: this.company.id, dataLogo });
        this.$alerta('Logomarca salva', 'is-success');
        this.logo = file;
        await this.showImage(file);
      } catch (error) {
        this.logo = null;
        this.$alerta('Erro ao salvar logomarca', 'is-danger');
      }
      this.loading.general = false;
    },
    showImage(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.imageUrl = e.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
  async mounted() {
    if (this.firstValidation) {
      if (this.company.contractAccepted) {
        this.$router.push({ name: 'CompanyValidation' });
      } else {
        this.$router.push({ name: 'Register' });
      }
    } else {
      await this.loadCompany();
    }
    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped>
.company-status-container {
  align-items: center;
  justify-content: center;
}

.custom-button {
  position: absolute;
  margin: 116px 0 0 116px;
  background-color: #a7a7a75b;
}

.custom-hero {
  padding: 1.5rem 1.5rem;
}

.custom-level-center {
  margin: 1rem auto;
  max-width: 40%;
}

.custom-logo-text {
  color: black;
  position: absolute;
  margin: 60px 0 0 10px;
  font-size: 0.95rem;
}

.custom-validation-justification {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.image-container {
  border-radius: 6px;
  height: 150px;
  margin: 0 auto;
  position: relative;
  width: 150px;
}

.image-color {
  background-color: white;
}

.image-wrapper {
  position: absolute;
}

.rm-1 {
  margin-right: 0.2rem;
}

.rm-2 {
  margin-right: 0.4rem;
}

@media (max-width: 768px) {
  .custom-level-center {
    max-width: 80%;
  }
}
</style>
