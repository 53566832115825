<template>
  <div>
    <Buyer v-if="company.buyer" />
    <Seller v-if="company.seller" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Buyer from './Buyer/Buyer';
import Seller from './Seller/Seller';

export default {
  name: 'Company',
  components: {
    Buyer,
    Seller,
  },
  computed: {
    ...mapState('companies', ['company']),
  },
};
</script>
