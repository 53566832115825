<template>
  <div>
    <b-loading is-full-page :active="loading.general" can-cancel></b-loading>

    <section class="hero is-primary is-bold">
      <div class="hero-body custom-hero">
        <div class="container is-title-fullhd">
          <div class="level">
            <div class="level-left"><h1 class="title">Prefeitura</h1></div>

            <div class="level-end has-text-centered">
              <div v-if="!loading.general" class="image-container image-color">
                <figure class="image-wrapper">
                  <img :src="imageUrl" />
                </figure>

                <p class="custom-logo-text" v-if="!logo">
                  <strong>Insira a logomarca</strong>
                </p>

                <b-field class="file">
                  <b-upload
                    accept="image/png,image/jpeg"
                    :disabled="disableFields"
                    ref="upload"
                    @input.native="event => sendLogo(event.target.files[0])"
                  >
                    <a :disabled="disableFields" class="button is-small custom-button">
                      <b-icon icon="upload"></b-icon>
                    </a>
                  </b-upload>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container is-fullhd">
        <div class="columns column-direction-touch">
          <div class="column is-narrow">
            <MenuSecundario titulo="Perfil" :itens="menu" />
          </div>
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import MenuSecundario from '@/components/MenuSecundario';
import { fileServer } from '@/utils/functions';
import { uploadLimit } from '@/utils/data';

export default {
  name: 'Buyer',
  components: {
    MenuSecundario,
  },
  data() {
    const loading = {
      general: false,
    };

    return {
      loading,
      logo: null,
      imageUrl: null,
      imagePreview: null,
    };
  },
  computed: {
    ...mapState('prefectures', ['prefecture']),
    ...mapGetters({
      chooseCompany: 'getChooseCompany',
      logged: 'getLogged',
      user: 'getUser',
    }),
    disableFields() {
      return (
        !this.logged ||
        !this.user.empresas.some(
          companyUser => companyUser.id === this.prefecture.id && companyUser.editar_empresa,
        )
      );
    },
    menu() {
      return [
        {
          titulo: 'Dados Cadastrais',
          rota: 'CompanyData',
          parametros: { id: this.prefecture.id },
        },
        {
          titulo: 'Usuários',
          rota: 'CompanyUsers',
          parametros: { id: this.prefecture.id },
        },
        {
          titulo: 'Configurações',
          rota: 'CompanyConfigurations',
          parametros: { id: this.prefecture.id },
        },
        {
          titulo: 'Chaves API',
          rota: 'CompanyKeys',
          parametros: { id: this.prefecture.id },
        },
        {
          titulo: 'Convidar Fornecedores',
          rota: 'CompanyInviteSuppliers',
          parametros: { id: this.prefecture.id },
        },
      ];
    },
  },
  methods: {
    ...mapActions('prefectures', ['fetchPrefecture', 'sendPrefectureLogo']),
    async loadPrefecture() {
      const prefectureId = this.chooseCompany ? this.prefecture.id : this.$route.params.id;

      await this.fetchPrefecture(prefectureId);

      if (this.prefecture.logo) {
        const url = await fileServer(this.prefecture.logo, 'logomarca');
        this.logo = url;
        this.imageUrl = url;
      }
    },
    async sendLogo(file) {
      const imageType = /image.(jpeg|png)$/i;
      if (!file.type.match(imageType)) {
        this.$alerta('Selecione arquivos com extensão .jpg ou .png', 'is-danger');
        this.$refs.upload.$refs.input.value = null;
        return;
      }
      if (file.size > uploadLimit) {
        this.$alerta('Insira um arquivo com até 50MB', 'is-danger');
        return;
      }

      this.loading.general = true;
      try {
        const dataLogo = new FormData();
        dataLogo.append('logo', file);
        await this.sendPrefectureLogo({ companyId: this.prefecture.id, dataLogo });
        this.$alerta('Logomarca salva', 'is-success');
        this.logo = file;
        await this.showImage(file);
      } catch (error) {
        this.logo = null;
        this.$alerta('Erro ao salvar logomarca', 'is-danger');
      }
      this.loading.general = false;
    },
    showImage(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.imageUrl = e.target.result;
      };

      reader.readAsDataURL(file);
    },
  },
  async created() {
    this.loading.general = true;
    try {
      await this.loadPrefecture();
    } catch (error) {
      this.$alerta('Erro ao buscar prefeitura', 'is-danger');
    } finally {
      this.loading.general = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  position: absolute;
  margin: 116px 0 0 116px;
  background-color: #a7a7a75b;
}

.custom-hero {
  padding: 1.5rem 1.5rem;
}

.custom-logo-text {
  color: black;
  position: absolute;
  margin: 60px 0 0 10px;
  font-size: 0.95rem;
}

.image-container {
  border-radius: 6px;
  height: 150px;
  margin: 1rem auto;
  position: relative;
  width: 150px;
}

.image-color {
  background-color: white;
}

.image-wrapper {
  position: absolute;
}

.increase-index {
  z-index: 0;
}
</style>
